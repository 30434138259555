import LazyLoad from "./modules/lazy-load.min.js";
import initSliders from "./modules/sliders.min.js";

// Initialize page
function initPage() {
  // Initialize Swup
  let swup = new Swup({
    animateHistoryBrowsing: true,
    containers: ["#swup", "#site-navbar"],
  });

  // Init scroll monitor
  transitionCallback();

  // Set transition callback
  swup.hooks.on("content:replace", transitionCallback);

  // Reload iubenda iframes
  swup.hooks.on("page:view", function () {
    if ($(".iubenda-embed").length > 0) {
      location.reload();
    }
  });
}

// Callback called on swup transition
function transitionCallback() {
  // Reinitialize lazy load
  new LazyLoad().init();

  // Reinitialize sliders
  initSliders();

  // Check if hero element is in view
  toggleLogo();

  // Iubenda check
  iubendaCheck();

  // Hide/Show reCaptcha badge based on page
  if ($("#contact-form").length > 0) {
    $(".grecaptcha-badge").addClass("visible");
  } else {
    $(".grecaptcha-badge").removeClass("visible");
  }

  // On scroll update logo visibility
  $(document).on("scroll", function () {
    toggleLogo();
  });
}

function removePreloader() {
  // Remove preloader
  setTimeout(function () {
    $(".preloader").fadeOut(500, function () {
      $(this).remove();
    });
  }, 500);
}

function isElementInViewport(el) {
  if (typeof jQuery === "function" && el instanceof jQuery) {
    el = el[0];
  }

  // Check if element exists
  if (!el) return false;

  let rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function toggleLogo() {
  // If hero element is in view
  if (isElementInViewport($("#herolapse-logo")[0])) {
    document.documentElement.dataset.show_logo = 0;
  } else {
    document.documentElement.dataset.show_logo = 1;
  }
}

function iubendaCheck() {
  // Remove iubenda cookie message
  setTimeout(function () {
    if (_iub.cs.consent.purposes !== undefined) {
      let purposes_iub = Object.keys(_iub.cs.consent.purposes);

      for (let i = 0; i < purposes_iub.length; i++) {
        if (_iub.cs.consent.purposes[purposes_iub[i]] == true) {
          // Hide cookie message for enabled purposes
          $(".content-before-consent-" + purposes_iub[i]).each(
            function (_, el) {
              el.style.display = "none";
            },
          );

          // Send events to GTM
          switch (purposes_iub[i]) {
            case "2":
              // Attivatore Tag Manager categoria Interazioni Semplici
              dataLayer.push({
                event: "iubenda-interazioni-semplici",
              });
              break;
            case "3":
              // Attivatore Tag Manager categoria Esperienza Migliorata
              dataLayer.push({
                event: "iubenda-esperienza-migliorata",
              });
              break;
            case "4":
              // Attivatore Tag Manager categoria Misurazione
              dataLayer.push({ event: "iubenda-misurazione" });
              break;
            case "5":
              // Attivatore Tag Manager categoria Pubblicità
              dataLayer.push({ event: "iubenda-pubblicita" });
              break;
          }
        } else {
          // Show cookie message for disabled purposes
          $(".content-before-consent-" + purposes_iub[i]).each(
            function (_, el) {
              el.style.display = "flex";
            },
          );
        }
      }
    } else {
      // Show cookie message for disabled purposes
      $(".content-before-consent").each(function (_, el) {
        el.style.display = "block";
      });
    }
    _iub.cs.api.activateSnippets();
  }, 500);
}

// Initialize page
$(window).on("load", function () {
  initPage();
  removePreloader();
});
